#chatList {
  width: 288px;
  min-height: 53px;  
  max-height: calc(100vh - 100px);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(15, 117, 188, 0.25);
  transition: ease-out .5s;
}

#chatList.open {
  height: calc(100vh - 100px);
}

#chatList:not(.open) header button svg {
  transform: rotate(180deg);
}

#chatList header {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 53px;
  align-items: center;
  justify-content: space-between;
  padding: 15px 13px 15px 20px;
  border-radius: 4px 4px 0px 0px;
  background: #0f75bc;
  border-bottom: 1px solid #D3E6FB;
  /* margin-bottom: 6px; */
}

#chatList header input {
  width: 100%;
  max-width: 165px;
  border-radius: 4px;
  height: 24px;
  background: #d4e6fb;
  border: none;
  color: #000000;
  font-family: 'Roboto';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding: 8px 23px;
  outline: none;
  /* text-align: center; */
}

::placeholder {
  color: #808080;
}

header button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background: none;
  outline: none;
  transition: ease-out .3s;
}

header button:hover {
  background: #eff7ff20;
}

#chatList header button svg {
  color: #ffffff;
}

header h1 {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.389px; /* 118.38% */
  letter-spacing: -0.143px;
  margin-bottom: 0;
  margin-right: 5px;
}
.input-container {
  position: relative;
}

.input-container label {
  cursor: pointer;
}

.input-container svg {
  position: absolute;
  top: 7px;
  left: 7px;
}

.chat-list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 21px;
}

.chat-list-item:hover {
  cursor: pointer;
  background-color: #f5f5f5;;
}

.chat-list-item .last-message {
  width: 124px;
  padding-left: 5px;
}

.chat-list-item h1 {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  padding: 0;
  margin: 7px 0;
}

.chat-list-item p {
  color: #000;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.chat-list-item p.viewed {
  font-weight: 400;
}

.chat-list-item .sent-at {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 7px;
  margin-left: 9px;
  width: 41.59px;
}

.chat-list-item .sent-at .time {
  color: #7F7F7F;
  text-align: right;
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 7px;
}

.chat-list-item .sent-at .badge {
  color: var(--text-utility, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 1px;
  background: #3373B6;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#chat {
  width: 336px;
  max-width: 500px;
  min-height: 53px;  
  max-height: calc(100vh - 355px);
  position: fixed;
  bottom: 0;
  right: calc(288px + 1.6rem);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 4px 4px 0px 0px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  transition: ease-out .5s;
}

#chat.open {
  height: 437px;
}

#chat.full.open {
  width: 500px;
  height: calc(100vh - 355px);
}

#chat header {
  height: 53px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid #358FEB;
  cursor: pointer;
}

#chat header .MuiAvatar-circle {
  margin-right: 5px;
}

#chat header button {
  width: 30px;
  height: 30px;
}

#chat header h1 {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 200% */
}

#chat header p {
  color: #000;
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */
  padding: 0;
  margin: 0;
}

#chat header > div {
  display: flex;
  height: 100%;
  align-items: center;
}

#chat main {
  height: calc(100% - 53px);
  overflow-y: scroll;
  padding: 10px 20px 0 20px;
}

#chat main .messagesContainer {
  min-height: calc(100% - 60px);
  padding-bottom: 25px;
}

#chat footer {
  position: relative;
  min-height: 56px;
  padding: 0;
  background-color: #FFF;
  position: sticky;
  margin: 0 -20px -10px -20px;
  bottom: 0;
  padding: 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

#chat footer p {
  float: none;
  text-align: left;
}

#chat-input {
  min-height: 36px;
  border-radius: 4px;
  width: calc(100% - 37px);
  background: #EFF7FF;
  outline: none;
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 200% */
  padding: 14px 32px 14px 13px;
}

#chat-input.placeholder {
  color: #808080;
}

#chat footer.full {
  height: 100%;
}

#chat footer.full #chat-input {
  height: 100%;
}

#chat-submit {
  width: 27px;
  height: 27px;
  background-color: #358FEB;
  border: none;
  border-radius: 50%;
  margin-bottom: 6px;
}

#chat-submit:hover {
  background-color: #287ED5;
}

#increase-input {
  width: 27px;
  height: 27px;
  background: none;
  border: none;
  border-radius: 50%;
  position: absolute;
  left: calc(100% - 90px);
  top: 6.5px;
}

#increase-input:hover {
  background-color: #DAE7F3;
}

.chat-message-component {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  gap: 10px;
}

.chat-message-component.sending {
  flex-direction: row-reverse;
}

.message-container {
  width: 100%;
  min-height: 26px;
  border: 0.7px solid #D3E6FB;
  border-radius: 0px 6px 6px 6px;
  color: #000;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  padding: 10px;
  box-sizing: border-box;
}

.message-container.sending {
  border-radius: 6px 0px 6px 6px;
}

.chat-message-component span {
  color: #808080;
  text-align: right;
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 8px;
  display: flex;
  align-self: flex-end;
}

.MuiAvatar-root.MuiAvatar-circle img {
  object-fit: contain;
}