.FileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}
.FileName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.custom-file-upload.disabled {
  color: #979797 !important;
  border: 1px solid #979797 !important;
  background-color: #fff !important;
  cursor: not-allowed;
}

.form-error-message {
  color: #f44336;
  font-size: 14px;
  margin-bottom: 14px;
}
