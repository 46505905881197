.registration-min .MuiFormControlLabel-root {
  margin-bottom: 0px;
  margin-right: 27px;
}
.label-w {
  min-width: 235px;
}
.registration-min h6 {
  font-weight: 500;
  font-size: 20px;
  padding-top: 13px;
}

.react-tel-input .form-control {
  height: 42px !important;
  width: 100% !important;
  border-color: #e4e8ed !important;
}

.react-tel-input:hover .form-control,
.react-tel-input:hover .flag-dropdown {
  border-color: #0e76bd !important;
}

.form-control:focus {
  box-shadow: none !important;
}
.seller-sect label {
  margin-bottom: 0px !important;
}
@media (max-width: 768px) {
  .seller-sect {
    flex-wrap: wrap;
  }
}
