.registration-min .MuiFormControlLabel-root {
  margin-bottom: 0px;
  margin-right: 27px;
}
.label-w {
  min-width: 235px;
}
.registration-min h6 {
  font-weight: 500;
  font-size: 20px;
  padding-top: 13px;
}
.privact-check .MuiFormControlLabel-root {
  margin-right: 5px;
  display: inline;
}
.privacy-label {
  cursor: pointer;
  padding-left: 8px;
}
@media only screen and (max-width: 990px) {
  .privact-check .MuiFormControlLabel-root {
    align-items: flex-start;
  }
  .privact-check .PrivateSwitchBase-root-5 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
