header {
  background-color: #fff;
  /* -webkit-box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);
  box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);  */
  position: relative;
  margin-bottom: 0px;
  padding-top: 28px;
  padding-bottom: 28px;
}

#basic-navbar-nav {
  position: relative;
  z-index: 1;
}

.header_s header {
  -webkit-box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);
  box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);
}

.surprise-box header {
  -webkit-box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);
  box-shadow: 0.33333rem 0 1.33333rem rgba(87, 87, 87, 0.1);
}
header .logo-img {
  max-width: 140px;
}

header .dropdown-menu {
  padding: 0px;
  overflow: hidden;
  border: 0px;
  -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.17);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #3b5681 !important;
  color: #ffffff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff !important;
}

.dropdown-item:hover {
  text-decoration: none;
}

header .dropdown-menu .dropdown-item {
  font-size: 14px;
  display: block;
  padding: 0.5rem 1rem;
}

header .dropdown-menu strong {
  font-size: 14px;
  color: #839097;
}

header .navbar-expand-lg .navbar-nav .nav-item .nav-link {
  padding: 0.25rem 0.75rem;
  margin-left: 0.5rem;
}

header .navbar-expand-lg .navbar-nav .nav-item:not(.dropdown) .nav-link.active {
  background: #1b75bc;
  color: #ffffff !important;
}

header .navbar-expand-lg .navbar-nav .nav-item .dropdown-item.isDisabled {
  background-color: transparent !important;
  cursor: default;
}

header .navbar-expand-lg .navbar-nav .nav-item .isDisabled:not(.dropdown-item),
header .navbar-expand-lg .navbar-nav .nav-item .dropdown-item.isDisabled a {
  opacity: 0.5;
  pointer-events: none;
  display: flex;
  align-items: center;
}

header .dropdown.active .dropdown-toggle {
  background: #1b75bc;
  color: #ffffff !important;
}

header .dropdown.active .dropdown-item.active {
  background: #1b75bc;
  color: #ffffff !important;
}

@media (max-width: 900px) {
  header {
    padding-bottom: 0px;
    padding-top: 18px;
  }

  header .navbar-expand-lg .navbar-nav .nav-item .nav-link {
    padding: 0.5rem 0.75rem;
    margin-left: 0;
  }

  .multi_language {
    top: -11px;
    z-index: 3;
  }
}
