.packageplan {
  margin-top: 30px;
  padding: 15px 0 0;
  box-sizing: border-box;
  width: 23.2%;
  color: #fff;
}

.packagetext {
  padding-left: 15px;
  padding-right: 15px;
}

.packageplan h4 {
  font-size: 25px;
  font-weight: bold;
}

.Crystal-pack {
  background: #88c8ee;
}

.flex-item {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.flex-item-2 {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.fill {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; */
}

/* .package-content {
  height: 350px;
  overflow: hidden;
  max-height: 350px;
  min-height: 350px;
} */

.btn {
  border: 0px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 9px;
  display: block;
  margin: 0px auto;
  width: 100%;
}

.btn:hover {
  color: #fff;
  background-color: #4f606a;
}

.packageprice {
  display: block;
  font-weight: 400;
  text-align: center;
  background: #50a1ce;
  padding: 13px 15px 11px;
  min-height: 72px;
}

.strikethrough {
  text-decoration: line-through;
  color: red;
  font-weight: 400;
  text-align: center;
  padding: 13px 15px 11px;
  min-height: 72px;
}

.packageplan ul {
  padding: 17px 0 30px;
  margin: 0px;
  text-align: left;
}
.packageplan li {
  position: relative;
  padding-left: 30px;
  margin-top: 5px;
  font-size: 16px;
}
.packageplan li:before {
  position: absolute;
  left: 4px;
  top: 1px;
  content: '';
  display: block;
  width: 8px;
  height: 13px;
  border: solid #fff;
  border-width: 0px 3px 3px 0;
  transform: rotate(45deg);
}

.Component-track-4 {
  background-color: #839097 !important;
  border-color: #839097 !important;
}

.packageplan .button_bg,
.package-min .button_bg.getstarted {
  width: 100%;
  background: #0c77a8;
  color: #fff;
  padding: 15px;
  border: 0px;
  text-align: center;
}
.package-min .button_bg.getstarted {
  width: auto;
  padding: 8px 15px 6px;
  border-radius: 4px;
  margin-left: 20px;
  float: right;
}

.package-min .modal-title {
  color: #88c8ee;
}

.package-min .modal-dialog {
  max-width: 609px;
}

.package-min p b {
  color: #88c8ee;
}

.price-div {
  background: #50a1ce;
  padding: 15px;
  float: left;
  color: #fff;
  width: 100%;
}

.Silver-pack {
  background: #81949f;
}

.Silver-pack .packageprice,
.Silver-pack .price-div {
  background: #6c7f89;
}

.Silver-pack.packageplan .button_bg,
.Silver-pack.modal .button_bg.getstarted {
  background: #4e5b63;
}


.Crystal-pack-plus  {
  background: #0E4F80 !important;
}
.Silver-pack-plus  {
  background: #323E45 !important;
}
.Gold-pack-plus  {
  background: #4C472F !important;
}
.Platinum-pack-plus  {
  background: #546673 !important;
}

.Silver-pack.modal .modal-title {
  color: #4e5b63;
}

.Gold-pack {
  background: #97906c;
}

.Gold-pack .packageprice,
.Gold-pack .price-div {
  background: #867f5f;
}

.Gold-pack.packageplan .button_bg,
.Gold-pack.modal .button_bg.getstarted {
  background: #69644b;
}

.Gold-pack.modal .modal-title {
  color: #69644b;
}

.Platinum-pack {
  background: #afbfc7;
}

.Platinum-pack .packageprice,
.Platinum-pack .price-div {
  background: #90a6b2;
}

.Platinum-pack.packageplan .button_bg,
.Platinum-pack.modal .button_bg.getstarted {
  background: #7b8c96;
}

.Platinum-pack.modal .modal-title {
  color: #7b8c96;
}

.Free-pack {
  background: #d4b414;
}

.Free-pack .packageprice,
.Free-pack .price-div {
  background: #dcc44d;
}

.Free-pack.packageplan .button_bg,
.Free-pack.modal .button_bg.getstarted {
  background: #d4b414;
}

.Free-pack.modal .modal-title {
  color: #d4b414;
}

.Crystal-pack.modal,
.Silver-pack.modal,
.Gold-pack.modal,
.Platinum-pack.modal,
.Free-pack.modal {
  background: transparent;
}
.modal ul {
  padding: 0px;
  margin: 0px 0 30px 0;
}

.package-min .modal-header {
  border: 0px;
  padding-bottom: 0px;
}

.pack_min {
  padding-bottom: 23px;
}

.btn-white {
  border-radius: 4px;
  background: #fff;
  color: #3b5680;
  text-transform: uppercase;
  border:1px solid white;
  padding: 9px 25px 6px;
  margin: 0px auto;
  font-weight: 500;
}

.Crystal-plus-btn {
  border-radius: 4px;
  background: #0E4F80;
  color: #fff;
  text-transform: uppercase;
  border:1px solid white;
  padding: 9px 40px 6px;
  margin: 0px auto;
  font-weight: 700;
}
.Silver-plus-btn {
  border-radius: 4px;
  background: #323E45;
  color: #fff;
  text-transform: uppercase;
  border:1px solid white;
  padding: 9px 40px 6px;
  margin: 0px auto;
  font-weight: 700;
}
.Gold-plus-btn {
  border-radius: 4px;
  background: #4C472F;
  color: #fff;
  text-transform: uppercase;
  border:1px solid white;
  padding: 9px 40px 6px;
  margin: 0px auto;
  font-weight: 700;
}
.Platinum-plus-btn {
  border-radius: 4px;
  background: #546673;
  color: #fff;
  text-transform: uppercase;
  border:1px solid white;
  padding: 9px 40px 6px;
  margin: 0px auto;
  font-weight: 700;
}

.modal-upgrade .modal-body {
  padding: 0px;
  padding: 0rem;
  border-radius: 14px;
}

.modal-upgrade .modal-content {
  border-radius: 1rem;
  width: 85%;
}

.modal-upgrade-header {
  border-radius: 14px;
  height: 160px;
  padding: 20px;
  -webkit-box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 0.53);
  box-shadow: 0px 5px 8px -2px rgba(0, 0, 0, 0.53);
  color: #fff;
}

.modal-upgrade-body {
  height: 6rem;
  padding: 2rem 1rem 1rem 1rem;
}

.title-modal-current {
  color: #fff;
  font-weight: bold;
  font-size: 3em;
}

.title-modal-upgrade {
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
}

.link-modal-benefits a {
  color: #060505 !important;
  cursor: pointer;
  text-decoration: underline;
}

.link-modal-benefits a:hover {
  font-weight: 600;
  text-decoration: underline;
}

.modal-btn-upgrade {
  color: #fff;
  text-align: center;
  background: #0095ff;
  border-radius: 1.5rem;
  padding: 0.7rem;
  width: 80%;
  margin: auto;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.modal-btn-upgrade:hover {
  background: #0170c0;
}

.link-modal-knowmore {
  margin: auto;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.link-modal-knowmore:hover {
  font-weight: 600;
  text-decoration: none;
}

.modal-btn-upgrade a {
  color: #fff !important;
}

.modal-btn-upgrade-close {
  position: absolute;
  right: 1.3rem;
  top: 1rem;
}

.crystal-upgrade-pack {
  background: rgb(136, 200, 238);
  background: linear-gradient(170deg, rgba(136, 200, 238, 1) 0%, rgba(236, 245, 250, 1) 95%);
}

.silver-upgrade-pack {
  background: rgb(129, 148, 159);
  background: linear-gradient(170deg, rgba(129, 148, 159, 1) 0%, rgba(236, 245, 250, 1) 95%);
}
.gold-upgrade-pack {
  background: rgb(105, 100, 75);
  background: linear-gradient(170deg, rgba(105, 100, 75, 1) 0%, rgba(236, 245, 250, 1) 95%);
}

.platinum-upgrade-pack {
  background: rgb(175, 191, 199);
  background: linear-gradient(170deg, rgba(175, 191, 199, 1) 0%, rgba(236, 245, 250, 1) 95%);
}

.connect-upgrade-pack {
  background: rgb(129, 148, 159);
  background: linear-gradient(170deg, rgba(129, 148, 159, 1) 0%, rgba(236, 245, 250, 1) 95%);
}

@media (max-width: 1023px) {
  .packageplan {
    width: 48%;
  }
}
@media (max-width: 668px) {
  .packageplan {
    width: 100%;
  }
  .package-min button.getstarted {
    float: none;
    margin-left: 0px;
    margin-top: 10px;
  }
}
