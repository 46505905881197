@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap');
@font-face {
  font-family: 'Jenna Sue';
  src: local('Jenna Sue'), url(./assets/fonts/JennaSue.ttf) format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');

/* sans serif: "Roboto" */
/* serif: "DM+Serif+Display" */

body {
  font-family: 'Roboto', sans-serif !important;
  color: #839097 !important;
  background: #fff !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 1200px;
}

a {
  color: #839097 !important;
}
a:hover {
  text-decoration: none !important;
}

a:focus {
  outline: none;
}

label {
  margin-bottom: 0.8rem !important;
}

.form-group {
  margin-bottom: 1.2rem !important;
}

pre {
  font-family: 'Roboto', sans-serif !important;
  white-space: break-spaces;
  color: #839097 !important;
  font-size: 16px;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
}

sup {
  color: #f44336;
}
.blue-bg {
  background: #0e76bd;
}

.w-bg {
  background: #fff;
}

.h-100 {
  height: 100% !important;
}

.w-85 {
  width: 100% !important;
}

.full-height {
  height: 100vh !important;
}
.mt-s-4 {
  margin-top: 0px !important;
}

.img-fluid-l img {
  max-width: 93% !important;
}
.display-none {
  display: none !important;
}
.border-radius {
  border-radius: 4px;
}

.minheight-100 {
  min-height: calc(100vh - 9rem);
}

.MuiInputLabel-root {
  font-size: 16px !important;
  color: #8ea0ab !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300 !important;
}

.MuiInputLabel-root.Mui-focused {
  color: #0e76bd !important;
}

.MuiButton-root {
  /* text-transform: capitalize !important; */
  text-transform: none !important;
  border-radius: 3px !important;
}

.MuiButton-contained:hover {
  background-color: #4f606a !important;
}

.MuiFormControl-marginNormal {
  margin-top: 16px !important;
  margin-bottom: 5px !important;
}
/* .MuiOutlinedInput-input {
  padding: 11.5px 14px !important;
} */
.create-account .MuiInputLabel-outlined,
.registration-min .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1);
}
/* .MuiCheckbox-colorPrimary.Mui-checked {
  color: #0e76bd !important;
  fill: #0e76bd !important;
} */
.MuiOutlinedInput-notchedOutline {
  border-color: #e4e8ed !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-1pahdxg-control:hover {
  border-color: #0e76bd !important;
  border-width: 1px !important;
}
.css-1pahdxg-control:hover {
  box-shadow: 0 0 0 1px #0e76bd !important;
}
.css-g1d714-ValueContainer {
  padding: 5px 8px !important;
}
.submit-btn.MuiButton-contained,
.btn-check {
  background: #3b5680;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 7px 10px;
  margin-top: 20px;
}
.submit-btn.MuiButton-contained {
  padding: 7px 28px;
  -webkit-box-shadow: 0 10px 20px rgba(19, 87, 129, 0.2);
  box-shadow: 0 10px 20px rgba(19, 87, 129, 0.2);
  display: block;
  margin: 33px auto;
}

.apply-filter.submit-btn.MuiButton-contained {
  margin: 38px auto;
}

.MuiFormControlLabel-root {
  justify-content: flex-start !important;
}
.MuiFormGroup-root {
  width: 100%;
}
.right-bordered-list-item {
  border-right: 1px solid #a5b2bb;
}
.left-bordered-list-item {
  border-left: 1px solid #a5b2bb;
}
.bordered-top-item {
  border-top: 1px solid #a5b2bb;
}
.bordered-bottom-item {
  border-bottom: 1px solid #a5b2bb;
}
.bordered-card-item {
  border-bottom: 1px solid #a5b2bb;
}
.list-item-separation {
  color: #a5b2bb;
  font-weight: 400;
  margin: 0 8px;
  opacity: 0.5;
}
.card-label {
  font-weight: 400;
  font-size: 14px;
  color: #a5b2bb;
  margin: 0;
}
.uppercase {
  text-transform: uppercase;
}
.top-heading h6 span {
  font-size: 32px;
}

.submit-btn.MuiButton-contained.edit_btn {
  padding: 10px 28px;
}

.load-btn.MuiButton-contained {
  background: #3b5680;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.btn-check {
  border: 0px;
  border-radius: 4px;
  margin-top: 0px;
  font-weight: 400;
  font-size: 16px;
  float: left;
  padding: 11px 25px 7px;
}

.valid-vat {
  margin-top: 8px;
  color: green;
}
.notvalid-vat {
  margin-top: 8px;
  color: #f44336;
}

.h_100 {
  height: 100vh;
}

.logo-img {
  max-width: 220px;
}
.registration-min .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0px;
}

.loader-min {
  height: 80vh;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.8);
  border-right: 1.1em solid rgba(255, 255, 255, 0.8);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.8);
  border-left: 1.1em solid #ddd;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.deleting-loader-min {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1001;
  top: 0;
}
.deleting-loader {
  position: absolute;
  left: 50%;
  top: 50%;
}
.loader-btn {
  height: 14px;
}
.loader-btn .loader {
  margin: 0px auto;
}
.loader-btn .loader {
  border-top: 3px solid rgba(255, 255, 255, 0.8);
  border-right: 3px solid rgba(255, 255, 255, 0.8);
  border-bottom: 3px solid rgba(255, 255, 255, 0.8);
  border-left: 3px solid #ddd;
}
.loader-btn .loader,
.loader-btn .loader:after {
  width: 14px;
  height: 14px;
}
.cursor-pointer {
  cursor: pointer;
}
.MuiFormHelperText-contained {
  margin-left: 0px;
}
.error-msg {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 0px;
  text-align: left;
}

.welcome-min {
  height: calc(100vh - 145px);
}

.welcome-min h2 {
  font-size: 36px;
  font-weight: bold;
}

.welcomepage .navbar-toggler {
  display: none;
}

.welcome-div span {
  font-size: 16px;
  display: block;
}

.welcome-div h1 {
  font-size: 54px;
  font-family: 'Shadows Into Light Two', cursive;
  color: #145b94;
  font-weight: bold;
}

.welcome-div {
  padding: 80px;
  box-sizing: border-box;
  max-width: 850px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
  margin: 10px auto 40px;
}
.welcome-div .MuiButton-root {
  background-color: #005496;
  font-weight: 500 !important;
}

.welcome-div a {
  color: #005496 !important;
  font-weight: 500;
}

.welcome-div .MuiButton-root span {
  font-size: 15px !important;
}

.error-icon {
  font-size: 3em !important;
  display: block;
  color: #f44336;
  margin: 0px auto 20px;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
button:focus {
  outline: none !important;
  outline: none !important;
}
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1200px !important;
}

.MuiOutlinedInput-root {
  border-radius: 3px !important;
}

.registration-min textarea {
  width: 100%;
  min-height: 130px;
  /* max-height:130px; */
  /* height:130px !important; */
  border: 1px solid#e4e8ed;
  padding: 11.5px 14px;
  border-radius: 3px;
  overflow-y: scroll !important;
}
.registration-min textarea:hover {
  border-color: #0e76bd;
}
.registration-min textarea:focus {
  border-color: #0e76bd;
  border-width: 1px;
  outline: none;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #3b5680;
  display: inline-block;
  padding: 9px 30px;
  cursor: pointer;
  background: #fff;
  color: #3b5680;
  border-radius: 3px;
  font-size: 16px;
}

.file-preview {
  margin: 0 10px;
}

/*== Create Acoount page Style ==*/
.create-account {
  padding-top: 0px;
  /* padding-bottom: 10px; */
  border-radius: 4px;
}
.social_icon {
  margin: 0px auto;
  padding: 0px;
  display: flex;
  text-align: center;
  width: 155px;
  justify-content: space-between;
}

.social_icon_login {
  margin: 0px auto;
  padding: 0px;
  display: flex;
  text-align: center;
  width: 155px;
  justify-content: space-between;
}

.social_icon_login button,
.social_icon_login a {
  border-radius: 3px;
  display: block;
  font-size: 17px;
  height: 40px;
  box-sizing: border-box;
  line-height: 2.53333rem;
  width: 2.53333rem;
  display: inline-block;
  text-align: center;
  border: 0px;
}

footer .social_icon {
  width: 260px;
}

footer .social_icon li {
  padding-left: 0px;
}

.social_icon button,
.social_icon a,
.buyer_social_icon a,
.buyer_social_icon span {
  border-radius: 3px;
  display: block;
  font-size: 17px;
  height: 40px;
  box-sizing: border-box;
  line-height: 2.53333rem;
  height: 2.53333rem;
  width: 2.53333rem;
  display: inline-block;
  text-align: center;
}
.buyer_social_icon a {
  color: #8ea0ab !important;
}

.buyer_social_icon a {
  /* line-height: 2.33333rem; */
  /* width: 2.33333rem;
  height: 2.33333rem; */
  font-size: 15px;
}

.social_icon button:hover,
.social_icon button:active,
.social_icon button:focus {
  outline: none;
  color: #fff;
}

.makeStyles-submit-4:hover {
  background-color: #4f606d !important;
}
.fb-bg {
  background-color: #4864a3;
  border: 1px solid #4864a3;
  color: #fff;
}

.twtt-bg {
  background-color: #3ba5cf;
  border: 1px solid #3ba5cf;
  color: #fff;
}

.g-bg {
  background-color: #e04a32;
  border: 1px solid #e04a32;
  color: #fff;
}

.l-bg {
  background-color: #007bb7;
  border: 1px solid #007bb7;
  color: #fff;
}

.buyer_social_icon a,
.buyer_social_icon span {
  background-color: #f5f5f5;
  margin-right: 15px;
}

.or-login span {
  background: transparent;
  position: relative;
  z-index: 3;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 16px;
  color: #4f606a;
}

.or-login::before,
.or-login::after {
  content: '';
  width: 45%;
  height: 1px;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 11px;
  left: 0px;
}

.or-login::after {
  left: auto;
  right: 0px;
}

.create-account {
  padding: 0px 0px;
  max-width: 350px;
  width: 95%;
  /* -webkit-box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
  box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05); */
}

.create-account .MuiOutlinedInput-notchedOutline {
  border-color: #d9d9d9;
}

.create-account .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.create-account .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0e76bd;
  border-width: 1px !important;
}

.sba-create-account label {
  font-size: 16px;
  margin-top: 5px;
}

.sba-create-account h2 {
  color: #202428;
}

/*== Checkbox Style ==*/
.custom-control {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 20px;
}

.registration-min .custom-control-label {
  padding-left: 10px;
  font-size: 15px;
}

.info-right .LinesEllipsis {
  font-size: 14px;
}

.registration-min .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #839097;
}

.registration-min .custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}

.registration-min .custom-checkbox.seller-check .custom-control-label::before {
  top: 0.1rem;
}

.registration-min .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: url(./assets/images/checkicon2.png) no-repeat left center;
  top: 0.47rem;
  left: -1.2rem;
  display: block;
  width: 11px;
  height: 9px;
}
.registration-min .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fff;
  border-color: #839097;
  background-size: 100%;
}
.registration-min .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.registration-min .custom-control-label::before {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  top: 0.15rem;
}
/*== Step Style ==*/

.formstep ul {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.formstep ul li {
  display: inline-block;
  width: 15%;
  position: relative;
}

.formstep ul li:last-child {
  width: auto;
}

.formstep ul li:after {
  content: '';
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  display: block;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.formstep span {
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  background: #fff;
  border-radius: 100px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  color: #3b5680;
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.33);
}

.formstep span.active-step {
  background: #3b5680;
  color: #fff;
  box-shadow: none;
}

.privact-check .MuiIconButton-label {
  margin-top: -3px;
}

.logo-img {
  max-width: 140px;
}

.onlinepresence-icon .fa-plus-circle {
  font-size: 26px;
  color: #d3e6fb;
  margin-left: 15px;
  margin-top: 9px;
}
.subscription-div {
  width: 187px;
  margin: 0px auto;
}

.subscription-div label.MuiGrid-root {
  flex-wrap: inherit !important;
}
.subscription-level div {
  width: 100%;
}

.download-btn.MuiButton-contained {
  padding-top: 10px;
  padding-bottom: 10px;
}

.progress {
  border-radius: 100px !important ;
  height: 0.6rem !important;
  width: 150px;
}

.progress-bar {
  width: 50%;
  border-radius: 100px;
  background-color: #d3e6fb !important;
}

.top-heading {
  padding: 7px 0 1px;
  margin-bottom: 50px;
  background: rgb(7, 69, 116);
  background: -moz-linear-gradient(
    left,
    rgba(7, 69, 116, 1) 0%,
    rgba(27, 116, 187, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(7, 69, 116, 1) 0%,
    rgba(27, 116, 187, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(7, 69, 116, 1) 0%,
    rgba(27, 116, 187, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#074574', endColorstr='#1b74bb',GradientType=1 ); /* IE6-9 */
}
.top-heading a {
  margin-top: 19px;
}
.registration-min .top-heading h6 {
  font-size: 54px;
  color: #fff;
  font-family: 'Shadows Into Light Two', cursive;
  font-weight: bold;
  text-transform: capitalize;
  padding-bottom: 6px;
}

.certificates-img {
  border-radius: 8px;
  border: 1px solid #8ea0ab;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
}
.pdf-btn button {
  border: 0px;
  margin-left: 5px;
  background-color: #3b5680;
  color: #fff;
  font-size: 12px;
  padding: 2px 7px;
  border-radius: 3px;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.certificates-img .fa-award,
.certificates-img .fa-trophy {
  color: #8ea0ab;
  font-size: 120px;
  margin-top: 45px;
  margin-bottom: 45px;
}

.removeFile {
  position: absolute;
  left: 8px;
  top: 8px;
  background: red;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
}

.date-month {
  background: url(./assets/images/date-bg.png) no-repeat left top;
  width: 74px;
  height: 74px;
  text-align: center;
  float: left;
  margin-right: 20px;
}

.date-month strong {
  color: #fff;
  padding-top: 4px;
  font-size: 15px;
  display: block;
}

.date-month span {
  color: #d3e6fb;
  padding-top: 0px;
  font-size: 30px;
  display: block;
  font-weight: bold;
}

.testimonial-info h5 {
  font-size: 18px;
  color: #839097;
  padding-top: 5px;
}

.testimonial-info pre {
  font-size: 15px;
  color: #839097;
  margin-bottom: 2px;
  font-weight: 500;
}

.testimonial-info pre span {
  font-weight: 400;
}

.testimonial-text ul,
.testimonial-info ul {
  margin: 0px;
  padding: 7px 0 0 0;
}

.testimonial-text li,
.testimonial-info li,
.review_rating li {
  display: inline-block;
  color: #ffb400;
  font-size: 15px;
  padding-right: 5px;
}

.sellers-info .review_rating ul {
  padding-left: 10px;
}

.sellers-info .review_rating li {
  padding-top: 0px;
  width: auto;
}

.testimonial-text p {
  position: relative;
}

.forgot-password {
  height: calc(100vh - 145px);
}

/*== Profile Header style ==*/
.logoimg_insert {
  width: 265px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #8ea0ab;
  text-align: center;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
  margin-bottom: 20px;
}

.logoimg_insert .fa-cloud-upload-alt {
  font-size: 45px;
}

.logoimg_insert span {
  display: block;
}

.person-presence-min label {
  padding-right: 15px;
}

.person-presence-min .fa-plus-circle,
.add_more_btn .fa-plus-circle {
  font-size: 25px;
  color: #d3e6fb;
  cursor: pointer;
}

.submit-btn.add_more_btn .fa-plus-circle {
  color: #fff;
}

.add_more_btn {
  cursor: pointer;
}

.remove-presence-p {
  color: #c82333;
  font-size: 20px;
  cursor: pointer;
}

.seller_detail_min {
  margin-top: 50px;
}

.seller_detail_min .nav .nav-item {
  margin: 0px 11px 0 0;
}
.seller_detail_min .nav .nav-item:last-child {
  margin: 0px 0px 0 0;
}
.border_tab {
  border-bottom: 1px solid #dbe1ea;
}

.profile-header .nav-tabs .nav-item .nav-link a,
.seller_detail_min .nav .nav-item .nav-link {
  padding: 0.75rem 1.2rem 0.65rem;
  display: block;
  background: #f7f8fc;
  color: #315a80 !important;
}

.seller_detail_min .nav .nav-item .nav-link {
  width: 180px;
  text-align: center;
  padding: 0.95rem 0.5rem 0.85rem;
}

.profile-header .nav-tabs .nav-item .nav-link a:hover,
.seller_detail_min .nav .nav-item .nav-link a:hover {
  border-color: #315a80;
  background: #315a80;
  color: #fff !important;
  text-decoration: none;
}

.profile-header .nav-tabs .nav-item,
.seller_detail_min .nav-tabs .nav-item {
  margin-right: 18px;
  transition: all 0.3s;
}

.profile-header .nav-tabs .nav-link,
.seller_detail_min .nav .nav-item .nav-link {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}

.profile-header .nav-tabs .nav-link.active,
.seller_detail_min .nav .nav-item .nav-link.active {
  border-color: #315a80;
  background: #315a80;
  color: #fff !important;
}

.nav-tabs .nav-link.active a,
.profile-header .nav-tabs .nav-item .nav-link.active a,
.seller_detail_min .nav .nav-item .nav-link.active a {
  background: #315a80;
  color: #fff !important;
}

.Certifications_v h4 {
  font-size: 16px;
  font-weight: 500;
}

.Certifications_v p {
  font-size: 14px;
}

/* == Login Page == */
.forgot-link a {
  font-size: 14px;
}
.forgot-link a u {
  font-size: 16px;
  font-weight: bold;
  color: #3b5680;
}

.remember-me label {
  margin-bottom: 0px !important;
}

.site-logo {
  margin-left: 12%;
}

.trash_file {
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  border-radius: 100px;
  background: #f44336;
  color: #fff;
  display: block;
}

/*== Online Presence ==*/
.online-presence-icon {
  height: 42px;
  line-height: 42px;
  width: 49px;
  text-align: center;
  border: 1px solid #e4e8ed;
  border-right: 0px;
  background: #f5f5f5;
}

.online-presence-min .MuiOutlinedInput-root {
  border-radius: 0px 3px 3px 0 !important;
}

.online-presence-min .MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: -21px;
}

.MuiChip-root {
  color: #8ea0ab !important;
  background-color: #f5f5f5 !important;
  border-radius: 2px !important;
}

.file-progress {
  justify-content: space-between;
}

/*== Sellers directory page ==*/
.sellers-img {
  border: 1px solid #dde3eb;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  max-width: 200px;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyers_min .sellers-img {
  height: 181px;
}

.sellers-img img {
  max-height: 100%;
}

.sellers-img .fa-image {
  font-size: 80px;
  margin: 55px 20px;
}

.sellers-info {
  margin-bottom: 20px;
  margin-top: 20px;
}

.sellers-info h5,
.sellers-info strong,
.sellers-info li b,
.sellers-info li b {
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.info-right .MuiRating-root label.MuiRating-label,
.MuiRating-root label.MuiRating-label {
  margin-bottom: 0px !important;
}

.give-review .MuiRating-root label.MuiRating-label {
  margin-bottom: 0.8rem !important;
}

.website-btn {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inherit;
}

.buyer_social_icon a.linkdin_icon {
  background-color: #0288d1 !important;
  color: #fff !important;
}

.buyer_social_icon a.fb_icon {
  background-color: #3b5998 !important;
  color: #fff !important;
}

.buyer_social_icon a.youtube_icon {
  background-color: #f44336 !important;
  color: #fff !important;
}

.buyer_social_icon a.twitter_icon {
  background-color: #03a9f4 !important;
  color: #fff !important;
}

.buyer_social_icon a.instagram_icon {
  background-color: #ff1340 !important;
  color: #fff !important;
}

.sellers-info strong .fa-map-marker-alt {
  font-size: 18px;
}

.sellers-info ul {
  padding: 0px;
  margin: 0px;
}

.sellers-info ul li {
  width: 50%;
  display: inline-block;
  padding-top: 15px;
}

.sellers-info ul.buyer_social_icon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sellers-info li b {
  display: block;
  padding-bottom: 5px;
  font-weight: 500;
}

.sellers-info ul.turnover-p li span,
.sellers-info ul.about_sellers_c li span {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.sellers-info li span {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sellers-info li b span {
  display: initial !important;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sellers-info ul.buyer_social_icon li span {
  overflow: visible;
}

.sellers-info p {
  font-size: 14px;
}

.sellers-info .info-right {
  border-left: 1px solid #dde3eb;
  padding-left: 25px;
}

.info-right a {
  color: #fff !important;
}
.seller-info-list ul {
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
}
.sellers-info .seller-info-list ul li,
.seller-info-list ul li {
  width: auto;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
}

.sellers-info .seller-info-list ul li small,
.seller-info-list ul li small {
  color: #8ea0ab;
  font-size: 13px;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 7px 10px 5px;
}

.seller-directory .seller-info-list {
  height: 70px;
  overflow: auto;
  margin-bottom: 13px;
}

.page-item.disabled .page-link,
.page-item:last-child .page-link {
  border: 0px;
}

.pagination_min .page-link {
  border-radius: 100px;
  margin-left: 18px;
  margin-right: 18px;
  padding: 0.3rem 0.55rem 0.1rem;
  color: #3b5680 !important;
  font-weight: bold;
  font-size: 15px;
}

.pagination_min .page-link2 {
  padding: 0px;
  height: 27px;
  width: 27px;
  text-align: center;
  line-height: 27px;
}

.pagination_min .page-item.active .page-link {
  background-color: #3b5680;
  border-color: #3b5680;
  color: #fff !important;
}

.pagination_min .page-link:hover,
.pagination_min .page-link:focus {
  background: transparent;
  box-shadow: none;
}

.buyers-info ul li {
  width: auto;
}

.reviews_min {
  padding-bottom: 20px;
}

.seller-details-min {
  padding-bottom: 25px;
}

.seller-details-min a:hover {
  text-decoration: none;
}

.info-right .fa-check {
  font-size: 12px;
  color: #339338;
}

.info-right .fa-times {
  color: #ea3546;
  font-size: 12px;
}

.side_filter {
  width: 100%;
  background: #fff;
  padding: 15px 15px 20px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.13);
}
.boxshadow {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.13);
}
.boxshadowtow {
  -webkit-box-shadow: 0px -2px 20px 0px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0px -2px 20px 0px rgba(50, 50, 50, 0.3);
  box-shadow: 0px -2px 20px 0px rgba(50, 50, 50, 0.3);
}
.side_filter .MuiInputBase-root {
  background: #fff;
}

.side_filter strong {
  font-weight: 500;
  font-size: 34px;
  color: #145b94;
  font-family: 'Shadows Into Light Two', cursive;
  font-weight: bold;
}

.side_filter span {
  color: #145b94;
  float: left;
  cursor: pointer;
  font-size: 15px;
}
.side_filter .MuiButton-root span {
  color: #fff;
}

.side_filter .PrivateSwitchBase-root-5 {
  padding: 9px 7px;
}

.registration-min .side_filter .MuiFormControlLabel-root {
  justify-content: space-between;
  margin-right: 0px;
}

.side_filter .MuiFormControl-root {
  width: 100%;
  justify-content: space-between;
}

.side_filter .MuiFormControlLabel-root span.MuiFormControlLabel-label {
  color: #839097;
}

.filter_h {
  overflow: auto;
  max-height: 204px;
}

.filter_h2 {
  height: 188px;
  overflow: auto;
}

.filter_h3 {
  height: 183px;
  overflow: auto;
}

.search-box {
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  padding: 4px 15px;
  position: relative;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.search-box.hovered,
.search-box:hover,
.search-box:active {
  border: 1px solid #aaa;
}

.search-box input[type='text'] {
  border: none;
  box-shadow: none;
  display: inline-block;
  padding: 0 10px;
  background: transparent;
}

.search-box input[type='text']:hover,
.search-box input[type='text']:focus,
.search-box input[type='text']:active {
  box-shadow: none;
}

.search-box .search-btn {
  width: 35px;
  color: #3b5680;
  border-radius: 3px;
  font-size: 21px;
  padding: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.search-box .search-btn:hover {
  background: transparent;
  color: #3b5680;
}

.Present,
.absent {
  font-size: 14px;
}

.absent::before,
.Present::before {
  content: '';
  width: 20px;
  height: 20px;
  background: #f1f2f4;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-right: 8px;
}

.Present::before {
  background: #6dacde;
  border-color: #6dacde;
}

/*== Seller details ==*/
.levers_growth ul {
  margin: 30px 0 0 0;
  padding: 0px;
  width: 18.5%;
}

.levers_growth li {
  width: 100%;
  background-color: #6dacde;
  border-radius: 3px;
  text-align: center;
  padding: 9px 10px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
  box-shadow: 0 5px 10px rgba(19, 87, 129, 0.2);
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.levers_growth li.colr_white {
  background-color: #f1f2f4;
  color: #a5b2bb;
  box-shadow: 0 5px 10px rgba(124, 124, 124, 0.2);
  color: #a5b2bb !important;
}

.levers_growth li.bgtransparent {
  background: transparent;
  box-shadow: none;
  color: #6dacde;
  font-size: 30px;
  padding: 0px;
  margin-bottom: 10px;
}

.levers_growth li:first-child {
  margin-bottom: 13px;
  font-weight: bold;
  text-transform: uppercase;
}

.levers_growth ul:nth-child(2) li.bgtransparent {
  color: #4594d0;
}

.levers_growth ul:nth-child(3) li.bgtransparent {
  color: #1c75bc;
}

.levers_growth ul:nth-child(4) li.bgtransparent {
  color: #145b94;
}

.levers_growth ul:nth-child(5) li.bgtransparent {
  color: #145b94;
}

.levers_growth li .fa-chevron-down {
  font-size: 19px;
}

.bg-color0 {
  background-color: #f1f2f4 !important;
  color: #a5b2bb !important;
}

.bg-color1 {
  background-color: #6dacde !important;
}

.bg-color2 {
  background-color: #4594d0 !important;
}

.bg-color3 {
  background-color: #1c75bc !important;
}

.bg-color4 {
  background-color: #145b94 !important;
}

.bg-color5 {
  background-color: #145b94 !important;
}
.bg-color6 {
  color: rgba(27, 116, 187, 1) !important;
  /* background-color: #6dacde !important; */
  /* background-color: rgba(#6dacde, 0.5) !important; */
  background-color: rgba(27, 116, 187, 0.2) !important;
}
.badge {
  background-color: #f1f2f4;
  color: #839097;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 8px !important;
}
.emptyMessage {
  text-align: center;
  padding: 16px;
}

.btn_no.MuiButton-contained,
.react-confirm-alert-button-group > button.btn_no {
  background: #3b5680;
  border-color: #3b5680;
  color: #fff;
}

.btn_yes.MuiButton-contained,
.react-confirm-alert-button-group > button.btn_yes {
  background: #f44336;
  border-color: #f44336;
  color: #fff;
}

.seller-details-body .modal.fade .modal-dialog {
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0) !;
  right: -780px;
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 780px;
  height: 100%;
}

.seller-details-body .modal.show .modal-dialog {
  right: 0px;
}

.seller-details-body .modal-content {
  border-radius: 0px;
  height: 100%;
  border: 0px;
}

.seller-details-body .modal-body {
  background-color: #f2f2f2;
  overflow-y: scroll;
}

.modal-body h2 {
  font-size: 23px;
  color: #839097;
}

.modal-body p {
  font-size: 14px;
}
.policypopup ul {
  margin-left: 40px !important;
}
.policypopup li {
  list-style: disc;
}
.policypopup .modal-dialog {
  max-width: 1000px;
  border-radius: 10px;
}

.policypopup .modal-content {
  height: 91vh;
  overflow-y: scroll;
}
.policypopup h3 {
  color: #1a75bb;
  font-size: 20px;
}

.policypopup .modal-header {
  display: block;
}

.policypopup .modal-header,
.page-titles {
  padding-top: 5px;
  padding-bottom: 5px;
  background: rgb(7, 69, 116);
  background: -moz-linear-gradient(
    left,
    rgba(7, 69, 116, 1) 0%,
    rgba(27, 116, 187, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(7, 69, 116, 1) 0%,
    rgba(27, 116, 187, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(7, 69, 116, 1) 0%,
    rgba(27, 116, 187, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#074574', endColorstr='#1b74bb',GradientType=1 ); /* IE6-9 */
}
.page-titles {
  padding-top: 20px;
  padding-left: 26px;
  padding-right: 15px;
  padding-bottom: 0px;
  width: 100%;
  margin-bottom: 30px;
}
.policypopup .modal-content {
  border: 0px;
}
.policypopup .row {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.policypopup .modal-header .modal-title,
.page-titles h4 {
  font-size: 50px;
  color: #fff;
  font-family: 'Shadows Into Light Two', cursive;
  font-weight: bold;
  text-transform: capitalize;
}

.seller-details-body .modal-body h5 {
  font-size: 20px;
  color: #839097;
  font-weight: 500;
}

.seller-details-body .modal-body p,
.sseller-details-body .modal-body span {
  font-size: 14px;
  line-height: 25px;
  padding-top: 5px;
}

/* .phone_number_f div:first-child{
  width: 100%;
} */

.c_code_min .MuiAutocomplete-endAdornment {
  width: auto;
}

.c_code_min .MuiOutlinedInput-root {
  border-radius: 3px 0px 0 3px !important;
}

.phone_number .MuiOutlinedInput-root {
  border-radius: 0px 3px 3px 0px !important;
}

.seller-details-min table {
  border: 1px solid #dde3eb;
}

.seller-details-min table thead,
.table-hover tbody tr:hover,
.registrat table thead,
.table-hover tbody tr:hover {
  background-color: #1a75bb;
  color: #fff;
}

.seller-details-min .table thead th {
  vertical-align: middle;
}

.seller-details-min .table-bordered td,
.seller-details-min .table-bordered th {
  border: 0px !important;
  text-transform: capitalize;
}

.seller-details-min .table-striped tbody tr:nth-of-type(2n + 1),
.seller-details-min .table-striped tbody tr:hover {
  background: #f4f9fc;
}

.registrat .table-striped tbody tr:nth-of-type(2n + 1),
.registrat .table-striped tbody tr:hover {
  background: #f4f9fc !important;
}
.table td,
.table th {
  border: 0px;
  font-size: 14px;
}

.seller-details-min table thead th {
  color: #fff;
}

.seller-details-min table td {
  font-size: 14px;
  color: #839097;
}

.seller-details-min button.view {
  background-color: #3b5680;
  border-radius: 4px;
  padding: 1px 10px 0px;
  color: #fff !important;
  font-size: 13px;
  margin: 5px;
  box-shadow: none;
}

.seller-details-min a.view {
  background-color: #3b5680;
  border-radius: 4px;
  padding: 1px 10px 0px;
  color: #fff !important;
  font-size: 13px;
  margin: 5px;
}

.seller-details-min a.edit {
  background-color: #1c75bc;
}

.seller-details-min button.view:hover,
.seller-details-min a.edit:hover,
.seller-details-min a.view:hover {
  background-color: #4f606a !important;
  box-shadow: none;
}

.Edit {
  background: #1c75bc !important;
}

.Delete {
  background: #f44336 !important;
}

.Close {
  background: #15af40 !important;
}

.lead_button a {
  color: #fff !important;
}

/*== Deal ==*/
.registration-min .lead_details h6 {
  font-size: 18px;
}

.lead_details ul {
  margin: 0px;
  padding: 0px;
}

.lead_details li {
  width: 100%;
  display: block;
  padding-bottom: 6px;
}

.lead_details li strong,
.lead_details strong {
  display: inline-block;
  font-weight: 500;
  width: 50%;
}

.lead_details strong {
  width: 100%;
}

.reject_btn.MuiButton-contained {
  background: #f44336;
}

/*== Need Page Style ==*/
.criteria_div {
  width: 30%;
}

.criteria_div3 {
  width: 50px;
  color: #f44336;
  font-size: 20px;
}

.delete-btn.MuiButton-contained {
  background: #f54337;
}

.publish-btn.MuiButton-contained {
  background: #15af41;
}

.date_f .MuiFormControl-marginNormal {
  margin-top: 13px !important;
}

.Certifications_v .Certifications_div:last-child hr {
  display: none;
}

/* .review_rate .MuiRating-iconFilled {
  color:#3b5680;
} */

.review_rate .MuiRating-iconEmpty {
  color: #d9e2e8;
}

.date_time_icon .MuiButtonBase-root {
  font-size: 1rem;
}

.date_time_icon .MuiOutlinedInput-adornedEnd {
  padding-right: 4px;
}
.buyers-info ul.about_sellers_c li {
  width: 60%;
}
.buyers-info ul.about_sellers_c li:first-child,
.buyers-info ul.about_sellers_c li:nth-child(2) {
  min-width: 90px;
  margin-right: 10px;
}

.buyers-info .submit-btn.MuiButton-contained {
  padding-left: 20px;
  padding-right: 20px;
}

.blogfilter .MuiFormControl-marginNormal {
  margin-top: 0px !important;
}

.blogfilter strong {
  font-family: 'Shadows Into Light Two', cursive;
  font-weight: bold;
  font-size: 34px;
  color: #005496;
  font-weight: normal;
}
.use_datl {
  font-size: 15px;
}
.use_datl .svg-inline--fa {
  color: #3b5680;
  font-size: 14px;
}

.has-search .form-control {
  padding-right: 2.375rem;
}
.has-search input {
  height: 42px;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1.1em;
  height: 42px;
  line-height: 42px;
  text-align: center;
  pointer-events: none;
  color: #3b5680;
  right: 26px;
}

.list-blog-img {
  height: 200px;
  overflow: hidden;
  text-align: center;
}
.list-blog-img img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.has-search .form-control:focus,
.has-search .form-control:hover {
  border-color: #0e76bd !important;
}

.card-text {
  font-size: 14px;
}

.blog-list .MuiButton-root {
  color: #fff !important;
  display: inline-block;
  width: 142px;
  position: relative;
  z-index: 2;
}

.blog_details a:link {
  color: #007bff !important;
  background-color: transparent;
  text-decoration: none;
}

.blog-list.card-deck .card,
.blog-list.card-deck {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.blog-list .card {
  border: 0px;
  min-height: 525px;
}

.card-title {
  color: #98a6ae;
  font-size: 20px;
  font-weight: 500;
}

.singleblog .card-title {
  font-size: 20x;
  font-weight: 500;
  color: #839097;
}

.singleblog ul {
  margin: 0px;
  padding: 0px;
}

.singleblog ul.post-blog li {
  display: inline-block;
  font-size: 15px;
  border-left: 1px solid #a7b0b4;
  line-height: 0px;
  padding: 0 10px;
}

.singleblog ul.post-blog li:first-child {
  border-left: 0px;
  padding-left: 0px;
}

.singleblog li .svg-inline--fa {
  color: #3b5680;
  font-size: 15px;
}

.singleblog {
  padding: 0rem 1.05rem 1.05rem 1.05rem;
}

.multi_language input[type='radio'] {
  opacity: 0;
  display: none;
}

.multi_language {
  position: absolute;
  right: 0px;
  top: -22px;
}

.multi_language label {
  margin-bottom: 0px !important;
  cursor: pointer;
  padding: 0.25rem 0.65rem !important;
  display: block;
  font-size: 13px;
}

.multi_language input[type='radio']:checked + label {
  font-weight: bold;
  color: #fff;
  background-color: #8ea0ac;
}

.multi_language .dropdown-item,
.multi_language .form-check {
  padding: 0px !important;
}

.multi_language .form-check img {
  width: 20px;
}

.multi_language .dropdown-item.active,
.multi_language .dropdown-item:active {
  background-color: #8ea0ac !important;
  color: #ffffff !important;
}

.multi_language .dropdown button.btn {
  padding: 0px;
  background-color: transparent;
  color: #4f606d;
  font-weight: normal;
  font-size: 13px;
  text-shadow: none;
}

.multi_language .btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  padding: 0px;
  background-color: transparent;
  color: #4f606d;
}

.multi_language .dropdown-toggle img {
  width: 20px;
}

.multi_language .dropdown-menu {
  min-width: 55px;
}

table.needtable tr td:nth-child(8) {
  text-align: center;
}

.sba_payment select {
  width: 99%;
  border: 1px solid #e4e8ed;
  padding: 0 6px;
  background-color: transparent;
  border-radius: 3px;
  font-size: 16px;
  height: 42px;
  line-height: 42px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(./assets/images/arrow.png) no-repeat right 18px center;
}

.progressLabel {
  width: 150px;
  text-align: center;
  margin-top: 9px;
}

.MuiPickersToolbar-toolbar,
/* .MuiPickersDay-daySelected {
  background-color: #3b5680 !important;
} */

.MuiButton-textPrimary {
  color: #3b5680 !important;
}

.pom_filter .date_time_icon .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.pom_filter.side_filter span {
  color: #839097;
}

.pom_filter Button.submit-btn span {
  color: #fff;
}

.pomdirectory h6 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}

.pomdirectory strong {
  font-weight: 500;
  font-size: 16px;
  display: block;
  padding-bottom: 15px;
}

.pomdirectory p {
  font-weight: 400;
  font-size: 15px;
}

.poms_content {
  height: 81px;
  overflow-y: auto;
}

.surprise-box h1,
.surprise-box h2 {
  color: #3b5680;
  font-size: 42px;
}

.surprise-box h2 {
  font-size: 26px;
}

.surprise-box-winner h1 {
  font-family: 'Shadows Into Light Two', cursive !important;
  font-weight: bold;
  font-size: 68px;
}

.colr_white {
  color: #fff !important;
}

.need-table .seller-info-list ul li {
  margin-right: 0px;
}
.need-table .seller-info-list ul li small {
  font-size: 12px;
  padding: 2px 5px 1px;
  display: block;
  background-color: #1a75bb;
  color: #fff;
}

.payment-option-min {
  padding-bottom: 10px !important;
  margin-top: -9px !important;
}
.payment-option {
  flex-direction: inherit !important;
}
.payment-option-min label {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}
.payment-option-min .MuiRadio-colorSecondary.Mui-checked {
  color: #3b5680 !important;
}

.seller-details-min a.view-icon {
  color: #1a75bb !important;
  font-size: 16px;
  margin: 4px;
}

.color-b {
  color: #005496 !important;
}

.seller-details-min button.view-icon {
  background: transparent;
  border-radius: 0px;
  padding: 0;
  font-size: 16px;
  margin: 5px;
  min-width: auto;
  box-shadow: none;
  color: #005496 !important;
}
.seller-details-min button.view-icon:hover {
  background: transparent !important;
  box-shadow: none;
  color: #005496 !important;
}
.registration-min h6.levers_growth_title {
  color: #145b94;
  font-family: 'Shadows Into Light Two', cursive !important;
  font-weight: bold;
  font-size: 30px;
}
.singlepage-content h3 b span,
.singlepage-content h3,
.singlepage-content h3 a {
  font-size: 25px !important;
  color: #145b94 !important;
  font-weight: bold;
}

.singlepage-content p span,
.singlepage-content p {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  color: #839097 !important;
  line-height: 24px !important;
}

.singlepage-content .singleblog li {
  border-left: 0px !important;
  display: block !important;
  list-style-type: disc !important;
  padding-left: 20px !important;
  font-size: 15px !important;
}
.singlepage-content .singleblog li span {
  font-size: 15px !important;
}

.flex-wrap {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
}

.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #fff;
  border: 1px solid #ccc;
  /* margin: 10px;
  padding: 10px; */
}

.flex-item {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.fill {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ck-editor__editable_inline {
  height: 300px;
}

.blog-social-icon {
  margin-top: -35px;
  position: relative;
  z-index: 1;
}
.blog-social-icon button,
.blog-icon button {
  margin-right: 2px;
  margin-left: 2px;
}
.b-0 {
  border: 0px;
}

.blog_details figure.media div {
  width: 100%;
}

.single-blog-detail .card-body .blog-post-editor li {
  border: 0px;
  padding: 0px;
  line-height: 24px;
  font-size: 15px;
}
.single-blog-detail .card-body .blog_details ol {
  list-style-type: decimal !important;
}

/* == media Screen Style == */
@media (min-width: 1400px) {
  .login-min {
    height: calc(100% - 85px);
  }
}

@media (min-width: 1365px) {
  .create-account {
    padding-top: 50px;
    /* padding-bottom:40px; */
  }
}

@media (max-width: 1200px) {
  .file-progress {
    flex-wrap: wrap;
    justify-content: safe;
  }
  .progress {
    width: 240px;
  }
  .buyers-info ul.about_sellers_c li {
    width: 50%;
  }
  .info-right .submit-btn.MuiButton-contained {
    font-size: 14px !important;
  }
  .tab_min .nav {
    width: 1150px;
  }
  .tab_min {
    overflow: scroll;
    width: 100%;
  }
}

@media (max-width: 990px) {
  .progress,
  .trash_file,
  .MuiFormGroup-root,
  .file-progress .submit-btn {
    margin-bottom: 10px !important;
  }

  .border_left {
    border: 0px;
    padding: 0px !important;
  }
  .buyers-info ul.about_sellers_c {
    flex-wrap: wrap;
  }
  .progressLabel {
    text-align: left;
  }
  .trash_file {
    margin-top: 5px;
  }
  .header_s .navbar-light .navbar-toggler {
    display: none;
    opacity: 0;
  }
}
@media (max-width: 768px) {
  .forgot-password {
    height: 100%;
    min-height: calc(100vh - 11rem);
  }

  .create-account-min .container {
    flex-wrap: wrap;
  }

  .create-account {
    margin: 0px auto;
  }

  .site-logo {
    display: none;
  }

  .levers_growth ul {
    width: 47%;
  }

  .submit-btn.MuiButton-contained,
  .btn-check {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .registration-min .top-heading h6 {
    font-size: 41px;
  }

  .side_filter strong {
    font-size: 35px;
  }

  .multi_language {
    right: 15px;
  }

  .welcome-div {
    padding: 20px;
  }
  .welcome-div h1 {
    font-size: 40px;
  }
  .welcome-div span {
    font-size: 14px;
  }
  .top-heading {
    padding-bottom: 15px;
  }
  .sellers-info .info-right {
    border-left: 0px;
    padding-left: 15px;
  }
  .levers_growth li {
    font-size: 13px;
    height: 47px;
  }

  .mt-s-4 {
    margin-top: 30px !important;
  }
}

@media (max-width: 568px) {
  .create-account.MuiContainer-root {
    padding-left: 15px;
    padding-right: 15px;
  }

  .profile-header {
    overflow-x: scroll;
  }

  .profile-header .nav-tabs {
    width: 438px;
    flex-wrap: unset;
  }

  .profile-header .nav-tabs .nav-item .nav-link a {
    font-size: 14px;
    padding: 0.55rem 1rem;
  }

  .profile-header .nav-tabs .nav-item {
    margin-right: 10px;
  }

  .trash_file {
    margin-top: 0px;
  }

  .buyer_social_icon a,
  .buyer_social_icon span {
    margin-right: 10px;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formSubLevel {
  background: #f5f5f5;
  padding: 1rem;
}

.formSubLevel_inputWrapper + .formSubLevel_inputWrapper {
  margin-top: 1rem;
}

.formSubLevel_inputWrapper label {
  margin-bottom: 0.5rem !important;
}

.formSubLevel_inputWrapper input {
  background: #fff;
}

.gap-3 {
  gap: 1rem;
}

.hidden-textfield > div {
  display: none;
}

.modalimage-small {
  width: auto !important;
  height: 80px !important;
  border-radius: 4px;
}

.modalimage-medium {
  width: 300px !important;
  height: auto !important;
  border-radius: 4px;
}

.modalimage-big {
  width: 520px !important;
  height: 300px !important;
  object-fit: cover;
  border-radius: 4px;
}
.modalimage-big-widthresponsive {
  width: auto !important;
  height: 320px !important;
  object-fit: cover;
  border-radius: 4px;
}

.MuiInputLabel-outlined {
  background-color: white !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

div[data-oembed-url] {
  height: 100%;
  width: 100%;
}

.MuiSnackbar-root {
  width: 100% !important;
}
.MuiSnackbarContent-root {
  margin: 0 auto !important;
  background-color: #f1f2f2 !important;
  color: #132332 !important;
}

.error-snackbar > * {
  background-color: #d32f2f !important;
  color: white !important;
  font-weight: bold;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
.block-title {
  color: #315A80;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.block-title strong {
  font-weight: 700;
}
.fix-spaces-in-form {
  padding-top: 24px;
}

.group-title {
  color: #358FEB;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 0;
}

.group-subtitle {
  color: #808080;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 25px;
  margin-bottom: 12px;
  margin-left: 15px;
}
.horizontal-checkboxes {
  flex-direction: column;
  gap: 77px;
}
.horizontal-checkboxes > div {
  display: flex;
  flex-direction: row;
  gap: 77px;
  /* width: 100%; */
  /* width: fit-content !important; */
}
.horizontal-checkboxes > div > div {
  width: fit-content;
}
.campaign-scoring {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 30px;
}

.campaign-scoring .dashed-row {
  height: 1px;
  width: 100%;
  border-bottom: 3px dashed #D3E6FB;
}

.campaign-scoring .scoring {
  min-width: 250px;
  height: 55px;
  border-radius: 8px;
  background: #FCEEF1;
  /* background: #D8F0DB; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.campaign-scoring .scoring .score {
  padding: 0;
  margin: 0;
  line-height: 17px;
}
.campaign-scoring .scoring .score strong{
  color: #132332;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
}

.campaign-scoring .scoring .score span {
  color: #F00;
  text-align: center;
  font-family: Roboto;
  font-size: 25px;
  line-height: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
}



.campaign-scoring .scoring.warning {
  background: #FFEBD9;
}
.campaign-scoring .scoring.warning  .score span {
  color: #FF7A00;
}

.campaign-scoring .scoring.ok {
  background: #D8F0DB;
}
.campaign-scoring .scoring.ok  .score span {
  color: #12B022;
}

.campaign-scoring .scoring .score span sub {
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  bottom: 0;
}
.scoring-message {
  border-radius: 10px;
  background: #FCEEF1;
  width: 179px;
  height: 76px;
  margin-left: auto;
  padding: 11px;
  margin-top: -15px;
  margin-bottom: 15px;
  margin-right: 7px;
}

.scoring-message span {
  display: block;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #ff0000;
  padding-right: 5px;
}

.scoring-message.warning {
  background: #FFEBD9;
}

.scoring-message.warning span {
  color: #FF7A00;
}

button, button * {
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
}
.details-group {
  display: flex;
  width: 100%;
}

.campaign-details-page .details-group .detail-group-title {
  color: #000;
  font-family: Roboto!important;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  margin: 44px 0 18px 0;
  padding-left: 0;
}
.campaign-details-page .campaign-details-title {
  color: #358FEB;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
  padding: 0;
  margin: 15px 0;
}

.campaign-details-page .campaign-title {
  color: var(--text-default-text, #132332);
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 96% */
  margin-top: 34px;
}

.campaign-details-page .campaign-details-subtitle {
  color: #000;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
}

.campaign-details-page .campaign-details-label {
  color: #358FEB;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px; /* 141.176% */
  margin-top: 35px;
  margin-bottom: 15px;
}
.campaign-details-page .campaign-details-label.italic {
  font-style: italic;
}
.campaign-details-page .campaign-details-label.h3 {
  margin-top: 25px;
}
.campaign-details-page .detail-text-content {
  display: block;
  color: #000;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 147.059% */
}

.campaign-details-list-values {
  padding-left: 0;
  list-style: disc;
}

.campaign-details-list-values li {
  margin-left: 40px;
}

.campaign-scoring-ok {
  background: #D8F0DB;
  width: fit-content;
  padding: 5px 25px;
  border-radius: 5px;
  min-width: 172px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.campaign-scoring-reason {
  display: block;
  margin-top: 15px;
}
.campaign-array-options {
  gap: 23.58px;
  margin-top: 18px;
}

.campaign-array-options .option {
  background-color: #D3E6FB;
  border-radius: 5px;
  padding: 10px 23px;
  min-width: fit-content;
  color: var(--text-accent-text, #358FEB);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}