.crystal-modal {
  position: fixed;
  background: #ffffff;
  min-height: 344px;
  top: 46%;
  margin-top: -122px;
  width: 550px;
  max-width: 90%;
  left: 50%;
  margin-left: -275px;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));
  z-index: 999;
}

.crystal-modal header {
  position: relative;
  min-height: 187px;
  height: fit-content;
  background-color: #358FEB;
  border-radius: 8px 8px 0 0;
  padding: 59px  0 36px;
}

.crystal-modal header h1 {
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 57.143% */
}

.crystal-modal header p {
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 120% */
  margin-top: 23px;
}

.crystal-modal main {
  padding: 35px 0 37px;
}

.crystal-modal main .formInput {
  position: relative;
  width: 381px;
  max-width: 90%;
  margin: auto;
  padding-right: 28.5px;
}
.crystal-modal main .formInput input {
  position: relative;
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #358FEB;
  margin: auto;
  padding: 0 42px;
  color: #808080;
  font-family: Roboto;
  font-size: 17.5px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 137.143% */
}

.crystal-modal .closeBtn {
  position: absolute;
  top: 17px;
  right: 0;
  font-size: 24px;
  z-index: 999;
}
.crystal-modal main .formInput .submitBtn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 57px;
  width: 57px;
  height: 57px;
  right: 0;
  top: 0;
  background-color: #358FEB;
  color: #FFFFFF;
  border-radius: 28.5px !important;
  padding: 0;
  font-size: 28px;
}

.crystal-modal main > span {
  color: #358FEB;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  display: block;
  margin: auto;
  margin-top: 15px;
}
.crystal-modal main span a {
  color: #358FEB !important;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.crystal-modal-backdrop {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background-color: #D3E6FB60;
}

@media (max-width: 767px) {
  .crystal-modal {
    position: fixed;
    background: #ffffff;
    min-height: 344px;
    top: 20%;
    width: 550px;
    max-width: 90%;
    left: unset;
    margin: auto;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));
    z-index: 999;
  }
}