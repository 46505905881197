.create-account-min {
  padding-bottom: 42px;
}

.create-account {
  padding-top: 55px;
  padding-bottom: 15px;
  border-radius: 4px;
}

@media (min-width: 1365px) {
  .create-account {
    padding-top: 50px;
  }
}
@media (max-width: 568px) {
  .create-account.MuiContainer-root {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.TermsLink {
  text-decoration: underline;
  color: #315a80 !important;
  cursor: pointer;
}

.MuiTabs-flexContainer {
  height: 100%;
}
.MuiTab-textColorInherit {
  opacity: 1 !important;
}
