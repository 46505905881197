footer {
  margin-top: 0px;
  background-color: #839097;
}

footer h6 {
  font-size: 20px;
  font-weight: 600;
  color: #1b75bc;
}

footer a {
  color: #fff !important;
  text-transform: uppercase;
}

footer ul {
  display: block;
  width: 100%;
  clear: both;
  text-align: right;
}

footer ul li {
  display: inline;
  font-size: 14px;
  padding-left: 5px;
  font-weight: 400;
  text-transform: uppercase;
}

footer ul.social_icon li:last-child {
  padding-left: 0px;
}

footer p {
  font-size: 15px;
  max-width: 365px;
  text-align: right;
  float: right;
  font-weight: 400;
  clear: both;
}

.footer-bottom {
  padding-top: 0px;
  padding-bottom: 25px;
  color: #fff;
  font-size: 17px;
  display: flex;
  align-items: end;
}

.footer-bottom span {
  font-size: 13px;
  text-align: left;
}

footer .social_icon a {
  background-color: #fff;
  color: #8ea0ab !important;
}

footer .social_icon a:hover {
  background-color: #3b5680;
  color: #fff !important;
}
@media (max-width: 1200px) {
  footer ul li {
    font-size: 13px;
    padding-left: 27px;
  }
}
@media (max-width: 768px) {
  footer p {
    max-width: 100%;
    text-align: left;
    float: left;
  }
  footer ul {
    text-align: left;
    margin: 0px;
    padding: 0px;
  }
  footer ul li {
    padding-left: 0px;
    padding-right: 20px;
  }
  .footer-social {
    flex-wrap: wrap;
  }
}

@media (max-width: 568px) {
  footer ul li {
    padding-left: 0px;
    padding-right: 12px;
  }
  .footer-social {
    margin-top: 25px;
  }
  footer .social_icon {
    width: 100%;
    display: block;
    text-align: left;
    margin-top: 10px;
  }
}
